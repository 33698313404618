import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
// import '@coreui/coreui/dist/css/coreui.min.css';
import './scss/style.scss';
import './scss/fonts/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const ThePublicLayout = React.lazy(() => import('./containers/ThePublicLayout'));

// Pages
const Login = React.lazy(() => import('./pages/login/Login'));
const Redirect = React.lazy(() => import('./pages/redirect/Redirect'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {
  render() {
    return (
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login"
            render={() => <ThePublicLayout component={Login} />}
          />

          <Route
            exact
            path="/redirect"
            name="Redirect"
            render={() => <ThePublicLayout component={Redirect} />}
          />

          <Route
            exact
            path="/404"
            name="Page 404"
            render={() => <ThePublicLayout component={Page404} />}
          />

          <Route
            exact
            path="/500"
            name="Page 500"
            render={() => <ThePublicLayout component={Page500} />}
          />

          <Route path="/" name="Home" render={(props) => <TheLayout {...props} />} />
        </Switch>
      </React.Suspense>
    );
  }
}

export default App;
